<template>
  <Head>
    <title>Подбор и поиск квартир по параметрам {{ $titleEnd }}</title>
    <meta
      name="description"
      content="Подбор квартиры в жилом комплексе VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА по параметрам – количество комнат, площадь, корпус, этаж, стоимость, отделка."
    />
    <meta
      property="og:title"
      :content="'Подбор и поиск квартир по параметрам ' + $titleEnd"
    />
    <meta
      property="og:description"
      content="Подбор квартиры в жилом комплексе VERY (Вери) на ул Ботаническая 29 в Москве от ГК ОСНОВА по параметрам – количество комнат, площадь, корпус, этаж, стоимость, отделка."
    />
    <meta
      property="og:image"
      content="https://api.gkosnova.tech/public/storage/media/2022/2/1200/oXzorkK8sRMBrx0yMrKq191wHUQIZLqNza1j99ke.jpg"
    />
  </Head>

  <main ref="main" class="main">
    <div class="container">
      <Breadcrumbs
        :links="[{ name: 'Home', text: 'Главная' }, { text: 'Уникальные лоты' }]"
      />
      <h1 class="page-title h2">Уникальные лоты</h1>
      <div class="plan-search">
        <div class="row no-gutters flex-nowrap">
          <Flats
            v-if="mq.lgPlus"
            showAll
            isCommerce
            hidePrice
            :flats="commerceFlats"
          />
          <FlatsMobile
            v-else
            showAll
            isCommerce
            hidePrice
            :flats="commerceFlats"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Flats from "@/components/FlatsTable.vue";
import FlatsMobile from "@/components/FlatsTableMobile.vue";
import commerceFlats from "@/content/unicLots.js";
import Breadcrumbs from "@/components/UI/Breadcrumbs.vue";
import { Head } from "@vueuse/head";
export default {
  inject: ["mainStore"],
  components: {
    Head,
    Flats,
    FlatsMobile,
    Breadcrumbs,
  },
  inject: ["mq", "filterStore", "mainStore"],
  computed: {
    commerceFlats () {
      return commerceFlats
    }
  },
  mounted () {
    // this.$vfm.show({
    //   component: 'PopupAparts',
    // })
  }
};
</script>
